import React, { Fragment } from 'react';
import LinkButton from '../components/Buttons/LinkButton/LinkButton';
import Hero from '../components/Hero/Hero';
import Seo from '../components/Seo/Seo';

const ProjectSubmittedPage = (): JSX.Element => (
    <Fragment>
        <Seo title="Project submitted" />
        <Hero height="xLarge">
            <Hero.Body containerWidth="small" className="items-center">
                <div className="text-center w-full space-y-2">
                    <h1 className="gold text-title">Thank you for your enquiry</h1>
                    <p className="text-paragraph">We'll be in touch soon regarding your project</p>
                    <LinkButton href="/" label="Back to home" />
                </div>
            </Hero.Body>
        </Hero>
    </Fragment>
);

export default ProjectSubmittedPage;
